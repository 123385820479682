<template>
  <div>
    <Menu />
    <div style="background-color: #255699; padding: 10px; position: fixed; bottom: 10px; right: 10px; z-index: 9999;">
        <a href="#form" style="color: #fff; text-decoration: none;">
            <strong style="color: #fff;">ENQUIRE NOW</strong>
        </a>
    </div>
    <section id="banner" class="my-5">
      <div class="container">
        <div class="row justify-content-center g-0">
          <div class="col-12">
            <div class="banner-section">
              <img src="/images/banners/asset-management.jpg" class="w-100" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="" class="mt-5">
      <div class="container switch-bg-lgrey bord-radius">
        <div class="row justify-content-center bord-radius">
          <div class="col-md-12 p-5 text-center">
            <h4>Seamless Property Asset Management for a Growing Portfolio</h4>
            <p class="mt-4">Your property portfolio is a significant part of your wealth, and at Chas Everitt, we treat it with the attention it deserves. Specialising in <strong>property asset management</strong>, we offer a complete service that ensures your assets are carefully managed, maximised for growth, and seamlessly maintained.</p>
            <p class="mt-4">With billions in assets under our care, our property asset managers handle every aspect – tenant management, maintenance, financial oversight, and legal protection – so you can focus on what matters most: <strong>growing your investments</strong>.</p>
          </div>
        </div>
      </div>
    </section>

    <section id="" class="mt-5">
      <div class="container p-0">
        <div class="row justify-content-center g-0">
          <div class="col-12 position-relative">
            <div class="switch-bg-blue bord-radius p-5 text-white">
              <center><h4 class="switch-white">We provide a comprehensive approach to property asset management</h4></center>
              <div class="container mt-5">
                <!-- Tabs navigation -->
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="properties-tab" data-bs-toggle="tab" data-bs-target="#properties" type="button" role="tab" aria-controls="properties" aria-selected="true">
                      Your Properties
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="tenants-tab" data-bs-toggle="tab" data-bs-target="#tenants" type="button" role="tab" aria-controls="tenants" aria-selected="false">
                      Your Tenants
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="finances-tab" data-bs-toggle="tab" data-bs-target="#finances" type="button" role="tab" aria-controls="finances" aria-selected="false">
                      Your Finances
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="legal-tab" data-bs-toggle="tab" data-bs-target="#legal" type="button" role="tab" aria-controls="legal" aria-selected="false">
                      Your Legal Needs
                    </button>
                  </li>
                </ul>
                <!-- Tabs content -->
                <div class="tab-content pt-4 bg-light p-4" id="myTabContent">
                  <div class="tab-pane fade show active" id="properties" role="tabpanel" aria-labelledby="properties-tab">
                    <p>Inspections, valuations, and ongoing maintenance to protect and enhance the value of your investments.</p>
                  </div>
                  <div class="tab-pane fade" id="tenants" role="tabpanel" aria-labelledby="tenants-tab">
                    <p>Strategic marketing and careful tenant vetting ensure reliable, long-term tenancies that optimise your returns.</p>
                  </div>
                  <div class="tab-pane fade" id="finances" role="tabpanel" aria-labelledby="finances-tab">
                    <p>We handle rent collection, manage cash flow, and provide clear financial reports, so you always know how your portfolio is performing.</p>
                  </div>
                  <div class="tab-pane fade" id="legal" role="tabpanel" aria-labelledby="legal-tab">
                    <p>Our expert team ensures your properties are fully compliant, with legally sound lease agreements that safeguard your interests.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5 switch-bg-lgrey bord-radius">
        <div class="row p-3">
          <div class="col-md-8">
            <h4>WHY CHOOSE CHAS EVERITT?</h4>
            <p>We manage every detail, so you can focus on growth. Here’s why property investors trust Chas Everitt to manage their property portfolios:</p>
            <div class="container bg-dark p-3 bord-radius">
              <div class="row">
                <!-- Vertical Tabs navigation -->
                <div class="col-lg-4">
                  <ul class="nav nav-tabs flex-column" id="verticalTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link-vertical active" id="asset-management-vertical-tab" data-bs-toggle="tab" data-bs-target="#asset-management-vertical" type="button" role="tab" aria-controls="asset-management-vertical" aria-selected="true">
                        Complete Asset Management
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link-vertical" id="success-vertical-tab" data-bs-toggle="tab" data-bs-target="#success-vertical" type="button" role="tab" aria-controls="success-vertical" aria-selected="false">
                        Proven Success
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link-vertical" id="portfolio-growth-vertical-tab" data-bs-toggle="tab" data-bs-target="#portfolio-growth-vertical" type="button" role="tab" aria-controls="portfolio-growth-vertical" aria-selected="false">
                        Strategic Portfolio Growth
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link-vertical" id="tailored-service-vertical-tab" data-bs-toggle="tab" data-bs-target="#tailored-service-vertical" type="button" role="tab" aria-controls="tailored-service-vertical" aria-selected="false">
                        Tailored Service
                      </a>
                    </li>
                  </ul>
                </div>
                <!-- Vertical Tabs content -->
                <div class="col-lg-8">
                  <div class="tab-content" id="verticalTabContent">
                    <div class="tab-pane fade show active" id="asset-management-vertical" role="tabpanel" aria-labelledby="asset-management-vertical-tab">
                      <div class="portfolio-block h-100 p-4 mx-1 bord-radius">
                        <p>We handle everything from tenant selection to financial management, ensuring your properties perform at their best.</p>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="success-vertical" role="tabpanel" aria-labelledby="success-vertical-tab">
                      <div class="portfolio-block h-100 p-4 mx-1 bord-radius">
                        <p>With billions of Rands in assets under management and a 'higher than average' lease duration, we deliver reliable returns.</p>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="portfolio-growth-vertical" role="tabpanel" aria-labelledby="portfolio-growth-vertical-tab">
                      <div class="portfolio-block h-100 p-4 mx-1 bord-radius">
                        <p>Our expert team not only manages your properties but also provides insights and advice to help you grow your portfolio.</p>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="tailored-service-vertical" role="tabpanel" aria-labelledby="tailored-service-vertical-tab">
                      <div class="portfolio-block h-100 p-4 mx-1 bord-radius">
                        <p>We understand that every portfolio is unique, and we tailor our approach to meet your specific goals and needs.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 pt-2">
            <img src="images/banners/asset-management-cover.png" class="img-fluid bord-radius">
          </div>
        </div>
      </div>
      <div class="container pt-5">
        <div class="row d-flex justify-content-center">
          <center>
            <h4>HOW WE WORK WITH YOU</h4>
            <p>Our process is designed to make property asset management effortless for you:</p>
          </center>
          <div class="col-lg-3 pt-4">
            <div class="portfolio-block h-100 p-4 mx-1 bord-radius">
              <p><strong>Before Occupation</strong></p>
              <p>We perform market evaluations, market your properties to the right audience, and carefully screen tenants to ensure a perfect match.</p>
            </div>
          </div>
          <div class="col-lg-3 pt-4">
            <div class="portfolio-block h-100 p-4 mx-1 bord-radius">
              <p><strong>During the Lease</strong></p>
              <p>From rent collection to maintenance and financial reporting, we handle every aspect of your property management to keep things running smoothly.</p>
            </div>
          </div>
          <div class="col-lg-3 pt-4">
            <div class="portfolio-block h-100 p-4 mx-1 bord-radius">
              <p><strong>End of Lease</strong></p>
              <p>We take care of final inspections, renewals, and transitions, ensuring continuity and performance for your portfolio.</p>
            </div>
          </div>
          <div class="col-lg-3 pt-4">
            <div class="portfolio-block h-100 p-4 mx-1 bord-radius">
              <p><strong>Facilities Management</strong></p>
              <p>Ensures the seamless upkeep of buildings and essential services, keeping operations running smoothly. By addressing maintenance needs proactively, it prevents costly downtime, extends asset lifespans, and creates a safe, comfortable environment for occupants.</p>
            </div>
          </div>
        </div>
      </div>
      <center>
        <h4 class="pt-5">Ready to see how Chas Everitt can elevate your property portfolio?</h4>
        <p>Download our brochure or get in touch to explore our property asset management services.</p>
        <a href="files/asset-management-brochure.pdf" target="_blank" class="btn btn-primary">Download our PDF brochure</a>
      </center>
    </section>
    <!-- form -->
    <a id="form"></a>
    <section>
      <div class="container p-md-5">
        <div class="row justify-content-center g-0 switch-bg-lgrey bord-radius">
          <div class="col-12 px-sm-5 pt-3">
            <div class="banner-section lifestyle-block p-3 h-100">
              <center><h4><strong>Send an enquiry message</strong></h4></center>
              <form id="buyForm" class="formStyle" @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <input type="text" class="form-control" v-model="data.name" placeholder="NAME" id="name">
                      <span class="error-message">{{ data.errors.name }}</span>
                    </div>
                    <div class="mb-3">
                      <input type="tel" class="form-control" v-model="data.phone" placeholder="PHONE" id="phone">
                      <span class="error-message">{{ data.errors.phone }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <input type="email" class="form-control" v-model="data.email" placeholder="EMAIL" id="email">
                      <span class="error-message">{{ data.errors.email }}</span>
                    </div>
                    <div class="mb-3">
                      <select class="form-control" id="enquiry" name="enquiry" required v-model="data.enquiry">
                        <option disabled selected value="ASSET MANAGEMENT QUERY">ASSET MANAGEMENT ENQUIRY</option>
                      </select>
                      <span class="error-enquiry">{{ data.errors.enquiry }}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="mb-3">
                      <textarea class="form-control" v-model="data.message" placeholder="WHAT ARE YOU LOOKING FOR?" id="message"></textarea>
                      <span class="error-message">{{ data.errors.message }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="col-md-12">
                    <div class="form-check mb-2">
                      <input type="checkbox" class="form-check-input" v-model="data.acceptTerms" id="acceptTerms">
                      <label class="form-check-label" for="acceptTerms">I have read and accept the <a target="_blank" href="https://www.chaseveritt.co.za/disclaimer-policy-website/" class="switch-blue">terms and conditions</a></label>
                    </div>
                    <div class="form-check mb-2">
                      <input type="checkbox" class="form-check-input" v-model="data.receiveMarketing" id="receiveMarketing" value="Yes">
                      <label class="form-check-label" for="receiveMarketing">I want to receive marketing material</label>
                    </div>
                  </div>
                  <div class="social-links switch-bg-dblue p-0 form-btn">
                    <button type="submit" class="btn switch-white" :disabled="!data.acceptTerms">SEND MESSAGE</button>
                  </div>
                  <div class="success-message pb-2" v-if="successMessage">{{ successMessage }}</div>
                  <div class="error-message pb-2" v-if="errorMessage">{{ errorMessage }}</div>
                </div>  
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Menu from '@/components/home/Menu.vue'
import Footer from '@/components/home/Footer.vue'
import SeoLinks from '@/components/home/SeoLinks.vue'
import axios from 'axios'

export default {
  components: {
    Menu, Footer, SeoLinks
  },
  data() {
    return {
      data: {
        name: "",
        email: "",
        phone: "",
        message: "",
        enquiry: "ASSET MANAGEMENT QUERY",
        receiveMarketing: false,
        acceptTerms: false,
        errors: {
          name: '',
          email: '',
          phone: '',
          message: '',
          enquiry: '',
        },
      },
      successMessage: '',
      errorMessage: '',
    }
  },
  methods: {
    submitForm() {

      this.data.errors = {};
      this.successMessage = '';
      this.errorMessage = '';

      if (!this.data.name) {
        this.data.errors.name = "Name is required";
      }

      if (!this.data.email) {
        this.data.errors.email = "Email is required";
      }

      if (!this.data.phone) {
        this.data.errors.phone = "Phone is required";
      } else if (!/^\d+$/.test(this.data.phone)) {
        this.data.errors.phone = "Phone must contain only numeric characters";
      }

      if (!this.data.message) {
        this.data.errors.message = "Message is required";
      }

      if (!this.data.enquiry) {
        this.data.errors.enquiry = "Message is required";
      }

      if (Object.keys(this.data.errors).length === 0) {
        axios.post("https://stratitude.co.za/api/buyForm.php", {
          name: this.data.name,
          email: this.data.email,
          phone: this.data.phone,
          message: this.data.message,
          enquiry: this.data.enquiry,
          receiveMarketing: this.data.receiveMarketing
        })
        .then(response => {
          this.successMessage = "Email sent successfully.";
          this.data.name = "";
          this.data.email = "";
          this.data.phone = "";
          this.data.message = "";
          this.data.enquiry = "";
          this.data.acceptTerms = false;
          this.data.receiveMarketing = false;
        })
        .catch(error => {
          this.errorMessage = "Email sending failed.";
        });
      }

    },
  },
}
</script>
<style scoped>
  .nav-link-vertical {
    background: none;
    border: none;
    color: #666;
    text-decoration: none;
  }
  .nav-link-vertical.active {
    color: #fff;
  }

</style>